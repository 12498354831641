<template lang="pug">
BasePane
  OmColorInput(
    size="lg"
    :backgroundSrc="backgroundSrc"
    typeOfComponent="image"
    isImage
    deviceSelector
    dsAllowed
  )
  Heading.mb-3.mt-2(expandableGap="12px" :open="clickableImage")
    template(#titleTrailing)
      .font-weight-bold {{ $t('clickableImage') }}
    template(#prependEnding)
      OmSwitch#clickableImage(v-model="clickableImage")
    template(#expandableContent)
      OmInput#url(
        :label="$t('url')"
        small
        placeholder="https://www.example.com/"
        v-model="redirectURL"
        :error="isRedirectUrlError"
        :errorText="errorText"
      )
      heading(label)
        template(#titleTrailing)
          .label {{ $t('keepQueryParams') }}
        template(#prependEnding)
          OmSwitch#keepURLParameters(v-model="keepURLParameters")
        template(#help)
          span {{ $t('keepQueryParamsTooltip') }}
      Heading(label)
        template(#titleTrailing)
          .label {{ $t('openInNewTab') }}
        template(#prependEnding)
          OmSwitch#openInNewTab(v-model="openInNewTab")
      ReportAs(v-model="reportAs")
      CampaignGoalReach(v-model="campaignGoalReach")
  Accordion(fix v-if="!isFloatingImage")
    template(#title)
      .font-weight-bold {{ $t('background') }}
    .color-wrapper
      OmColorInput(
        v-if="hasBackground('column')"
        :label="$t('column')"
        typeOfComponent="column"
        layout="col"
        dsAllowed
      )
      OmColorInput.mt-2(
        v-if="hasBackground('row')"
        :label="$t('block')"
        typeOfComponent="row"
        layout="col"
        dsAllowed
      )
      OmColorInput.mt-2(
        v-if="hasBackground('page')"
        :label="$t('currentPage')"
        property="selectedPage.$device.background.color"
        typeOfComponent="page"
        layout="col"
        dsAllowed
      )
  hr.mt-5.mb-4(v-if="!isFloatingImage")
  Accordion(fix)
    template(#title v-if="!isFloatingImage")
      .font-weight-bold {{ $t('sizeAndSpacing') }}
    template(#device v-if="!isFloatingImage")
      DeviceSelector(:hasViewText="false")
    RangeInput(
      :label="$t('width')"
      :min="10"
      :max="1000"
      :step="5"
      v-model="width"
      v-bind="imageProps"
    )
    AlignDropdown(
      v-if="selectedElement.type !== 'OmFloatingImage'"
      v-model="alignment"
      layout="col"
    )
    Margin(v-if="!isFloatingImage")
  hr.mt-3.mb-3
  template(#advancedContent)
    Hide.sidebar-spacing-top(
      :hideOnDesktop.sync="hideElementOnDesktop"
      :hideOnMobile.sync="hideElementOnMobile"
    )
    Border(typeOfComponent="image")
    CornerRadius
    Shadow(v-model="shadow" editMobile typeOfComponent="image")
</template>
<script>
  import { get as _get } from 'lodash-es';
  import { mapState, mapGetters } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import { ELEMENTS } from '@/../../../libraries/template-properties/src/propertyHelper';
  import BasePane from '@/editor/components/sidebar/panes/level2/BasePane.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import ReportAs from '@/components/Editor/Controls/ReportAs.vue';
  import CampaignGoalReach from '@/components/Editor/Controls/CampaignGoalReach.vue';
  import AlignDropdown from '@/components/Editor/Controls/Alignments/AlignDropdown.vue';
  import Shadow from '@/components/Editor/Controls/Shadow.vue';
  import Border from '@/components/Editor/Controls/Border.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import CornerRadius from '@/components/Editor/Controls/CornerRadius.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import DeviceSelector from '../../components/DeviceSelector.vue';

  export default {
    components: {
      BasePane,
      Heading,
      Accordion,
      RangeInput,
      ReportAs,
      CampaignGoalReach,
      AlignDropdown,
      Shadow,
      Border,
      Margin,
      CornerRadius,
      Hide,
      DeviceSelector,
    },
    mixins: [itemMixin],
    data() {
      return {
        canEditMobile: true,
      };
    },
    computed: {
      ...mapState([
        'selectedElement',
        'selectedPage',
        'selectedColumn',
        'selectedRow',
        'images',
        'mobilePreview',
        'themeKit',
        'validationError',
      ]),
      ...mapGetters(['nestedAccess', 'selectedImage', 'isEmbedded']),
      imageProps() {
        if (this.isFloatingImage) return { editMobile: true };
        return { editMobile: false };
      },
      reportAs: {
        get() {
          return this.getValueOf('data.reportAs');
        },
        set(value) {
          this.setValueOf('data.reportAs', value);
        },
      },
      campaignGoalReach: {
        get() {
          return this.getValueOf('data.goalReached');
        },
        set(v) {
          this.setValueOf('data.goalReached', v);
        },
      },
      alignment: {
        get() {
          return this.getValueOf('align');
        },
        set(value) {
          this.setValueOf('align', value);
        },
      },
      width: {
        get() {
          const mobileValue = this.getValueOf('mobile.smartSize.width');
          if (this.mobilePreview && !mobileValue) {
            return Number(this.getValueOf('desktop.smartSize.width'));
          }
          return Number(this.getValueOf('$device.smartSize.width'));
        },
        set(v) {
          this.smartSetValueOf('$device.smartSize.width', v);

          const calcWidthInEm = this.smartGetValueOf('mobile.calcWidthInEm');
          if (this.mobilePreview && !calcWidthInEm) {
            this.smartSetValueOf('mobile.calcWidthInEm', true);
          }
        },
      },
      clickableImage: {
        get() {
          return this.getValueOf('data.clickableImage');
        },
        set(v) {
          this.setValueOf('data.clickableImage', v);
          const version = this.getValueOf('data.version');
          if (version && version === 1) {
            this.setValueOf('data.version', 2);
          }
        },
      },
      redirectURL: {
        get() {
          return this.getValueOf('data.redirectUrl');
        },
        set(v) {
          this.setValueOf('data.redirectUrl', v);
        },
      },
      keepURLParameters: {
        get() {
          return this.getValueOf('data.keepQueryParams');
        },
        set(v) {
          this.setValueOf('data.keepQueryParams', v);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      openInNewTab: {
        get() {
          return this.getValueOf('data.newTab');
        },
        set(v) {
          this.setValueOf('data.newTab', v);
        },
      },
      customThemeRounding: {
        get() {
          return this.getValueOf('border.customThemeRounding');
        },
        set(v) {
          this.setValueOf('border.customThemeRounding', v);
        },
      },
      shadow: {
        get() {
          return this.getValueOf('shadow.type');
        },
        set(value) {
          this.setValueOf('shadow.type', value);
        },
      },
      isRedirectUrlError() {
        return this.validationError.property === 'data.redirectUrl';
      },
      errorText() {
        if (!this.isRedirectUrlError) return '';

        return this.$t(this.validationError.msg);
      },
      isFloatingImage() {
        return this.selectedElement.type === ELEMENTS.OmFloatingImage;
      },
      hasMobileImage() {
        return this.getValueOf('mobile.background.imageId');
      },
      backgroundSrc() {
        if (this.mobilePreview && this.hasMobileImage) {
          return this.smartGetValueOf('$device.background.imageUrl');
        }
        return this.smartGetValueOf('desktop.background.imageUrl');
      },
      device() {
        return this.mobilePreview ? 'mobile' : 'desktop';
      },
    },
    methods: {
      hasBackground(element) {
        let selectedPrefix = 'selectedPage';

        if (element === 'column') selectedPrefix = 'selectedColumn';
        if (element === 'row') selectedPrefix = 'selectedRow';

        const backgroundType = _get(this[selectedPrefix], `${this.device}.background.type`);
        if (backgroundType && backgroundType !== 'transparent') return true;

        return false;
      },
    },
  };
</script>
